body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  background-color: #212529;
  color: #f8f9fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  width: 30%;
  margin: auto;
  border-radius: 50%;
}

.feature-image {
  width: 30%;
  margin: auto;
}

.title {
  font-family: serif;
  font-weight: 700;
}

.close-lines > p {
  margin:  0;
}
